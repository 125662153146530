<template lang="pug">
    Extension.signer(v-bind="$props", @delete="deleteNode", @settings="showSettings", :settings="true", :fillable="false" :signerOnly="true" @save="save" @dismiss="dismiss" :filled="filled")
        template(#editable)
            span.field(:class="{multiline}")
                v-icon mdi-lead-pencil
                span Signer writes here
        template(#preview)
            v-textarea.signature(:value="input", @input="updateInput", :placeholder="placeholder" v-if="multiline")
            v-text-field.signature(:value="input", @input="updateInput", :placeholder="placeholder" v-else)
        template(#popup-data-writer)
            v-checkbox(v-model="multilinePopup" label="Text field should be multiline")
            v-text-field(v-model="placeholderText" placeholder="Placeholder for field")
        template(#popup-support)
            p This widget will display a text input for the user to fill in with custom text.
            br
            p If you select multiline then the user will be presented with a text field that allows multiple lines of text.
</template>

<script>
import { nodeViewProps } from '@tiptap/vue-2'
import Extension from "./Extension.vue";
import {nanoid} from "nanoid";

export default {
    editorParams: {
        name: 'vueInput',
        attrs: {
            input: {
                default: null
            },
            multiline: {
                default: false
            },
            placeholder: {
                default: 'Please fill in'
            }
        },
        tag: 'vue-input',
        group: 'inline',
        inline: true,
        draggable: true,
    },
    components: {Extension},
    props: nodeViewProps,

    data(){
        return{
            modal: false,
            multilinePopup: this.multiline,
            placeholderText: this.placeholder
        }
    },

    methods: {
        updatePlaceholder(value){
            this.updateAttributes({
                placeholder: value,
            })
        },
        updateInput(value){
            this.updateAttributes({
                input: value,
            })
            if(!this.editor.isEditable){
                this.$store.commit('setSignedField', {
                    uuid: this.node.attrs.uuid,
                    value: value
                })
            }
        },

        save(){
            this.updateAttributes({
                placeholder: this.placeholderText,
                multiline: this.multilinePopup
            })
        },
        dismiss(){
            this.placeholderText = this.placeholder;
            this.multilinePopup = this.multiline;
        },

        showSettings() {
            this.modal = true;
        }
    },

    computed:{
        input(){
            return this.node.attrs.input;
        },
        placeholder(){
            return this.node.attrs.placeholder;
        },
        multiline(){
            return this.node.attrs.multiline;
        },
        filled(){
            if(this.input){
                return true;
            }else{
                return false;
            }
        }
    },
}
</script>



<style lang="scss" scoped>

span.field{
    border-bottom: 1px solid #AAAAAA;
    display: inline-block;
    padding: 10px;

    color: #777777;

    ::v-deep .v-icon{
        font-size: 17px;
        margin-right: 5px;
        margin-top: -2px;
    }

    &.multiline{
        padding: 50px;
    }
}





::v-deep .v-textarea{
    width: 250px;
    height: 150px;

    padding-top: 0px;
}

::v-deep .v-input{
    padding-top: 0px;

    .v-text-field__details{
        display: none;
    }
}
</style>
