var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor-preview"},[_c('v-card',{staticClass:"document-holder"},[_c('v-card-text',[_c('editor-content',{attrs:{"editor":_vm.editor}})],1)],1),(!_vm.hideScheduleA)?_c('v-card',{staticClass:"schedule-a"},[_c('v-card-title',[_vm._v("Schedule A")]),_c('v-card-text',[(_vm.songs)?_c('div',{staticClass:"songs"},[_c('v-data-table',{attrs:{"headers":_vm.scheduleA,"items":_vm.songs,"disable-pagination":"","hide-default-footer":"","no-data-text":_vm.empty,"hide-default-header":!_vm.songs.length},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))]),_c('div',[_c('small',[_vm._v(_vm._s(item.artist))])])]}},{key:"item.composers",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"composers rights",class:{error: _vm.totalRights(item.royalties, 'songwriting') !== 100}},[_vm._l((_vm.getRightsholders(item.royalties, 'songwriting')),function(composer){return _c('div',{staticClass:"composer"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(composer.name))]),(composer.industry_id)?_c('span',{staticClass:"bmi"},[_vm._v(" ("+_vm._s(composer.pro_type)+" "+_vm._s(composer.industry_id)+")")]):_vm._e(),_c('span',{staticClass:"percentage"},[_vm._v(" - "+_vm._s(composer.pivot.songwriting)+"%")])])}),_c('div',{staticClass:"total"},[_c('b',[_vm._v("Total: "+_vm._s(_vm.totalRights(item.royalties, 'songwriting'))+"%")])])],2)]}},{key:"item.publishers",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"publishers rights",class:{error: _vm.totalRights(item.royalties, 'publishing') !== 100}},[_vm._l((_vm.getRightsholders(item.royalties, 'publishing')),function(composer){return _c('div',{staticClass:"publisher"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(composer.name))]),(composer.industry_id)?_c('span',{staticClass:"bmi"},[_vm._v(" ("+_vm._s(composer.pro_type)+" "+_vm._s(composer.industry_id)+")")]):_vm._e(),_c('span',{staticClass:"percentage"},[_vm._v(" - "+_vm._s(composer.pivot.publishing)+"%")])])}),_c('div',{staticClass:"total"},[_c('b',[_vm._v("Total: "+_vm._s(_vm.totalRights(item.royalties, 'publishing'))+"%")])])],2)]}},{key:"item.masters",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"masters rights",class:{error: _vm.totalRights(item.royalties, 'mastering') !== 100}},[_vm._l((_vm.getRightsholders(item.royalties, 'mastering')),function(composer){return _c('div',{staticClass:"master"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(composer.name))]),(composer.industry_id)?_c('span',{staticClass:"bmi"},[_vm._v(" ("+_vm._s(composer.pro_type)+" "+_vm._s(composer.industry_id)+")")]):_vm._e(),_c('span',{staticClass:"percentage"},[_vm._v(" - "+_vm._s(composer.pivot.mastering)+"%")])])}),_c('div',{staticClass:"total"},[_c('b',[_vm._v("Total: "+_vm._s(_vm.totalRights(item.royalties, 'mastering'))+"%")])])],2)]}}],null,false,608407476)})],1):_vm._e()])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }