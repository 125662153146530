<template lang="pug">
    Extension(v-bind="$props" @edit="modal=true" @delete="deleteNode" name="Date widget" @save="dismiss" @dismiss="dismiss" :fillable="false")
        template(#editable)
            .date {{ dateString }}
        template(#preview)
            span {{dateString}}
        template(#popup-support)
            p This widget will display the current date at which the contract is opened by the signer.
</template>

<script>
import { nodeViewProps } from '@tiptap/vue-2'
import Extension from "./Extension.vue";

export default {
    editorParams: {
        name: 'vueDate',
        attrs: {
            date: {
                default: ''
            },
            automaticDate: {
                default: true
            }
        },
        tag: 'vue-date',
        group: 'inline',
        inline: true,
        draggable: true,
    },
    components: {Extension},
    props: {
        editable: {
            type: Boolean,
            default: false
        },
        ...nodeViewProps
    },

    data(){
        return{
            modal: false
        }
    },

    computed: {
        dateString(){
            let date = new Date();
            return date.toISOString().substr(0, 10);
        },
        filler(){
            return this.node.attrs.filler;
        },
    },
    methods: {
        dismiss(){
            this.datepicker = false;
        },
    },
}
</script>



<style lang="scss" scoped>
.date{
}

::v-deep .v-icon{
}

::v-deep .v-card__text{
    margin-top: 21px;
}

</style>

<style lang="scss">
.v-picker.writer-picker {
    margin-top: 15px;
}
</style>
