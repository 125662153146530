<template lang="pug">
    Extension(v-bind="$props" @delete="deleteNode" :fillable="false" ref="element" :allow-selection="true" :replaced="true")
        template(#render) {{name}}
        template(#popup-fields)
            b No editable fields
        template(#popup-support)
            p This widget will be replaced with the user name.
</template>

<script>
import { nodeViewProps } from '@tiptap/vue-2'
import Extension from "./Extension.vue";

export default {
    editorParams: {
        name: 'vueUserName',
        attrs: {},
        tag: 'vue-user-name',
        group: 'inline',
        inline: true,
        draggable: true,
    },
    components: {Extension},
    props: nodeViewProps,

    data(){
        return{
        }
    },

    computed:{
        name(){
                return this.$store.state.signingUser || '[User name]'
        }
    },
}
</script>



<style lang="scss" scoped>
</style>
