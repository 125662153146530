import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'

export default {
    render(component){
        if(!component || !component.editorParams){
            return null;
        }
        const params = component.editorParams;
        return Node.create({
            name: params.name,
            group: params.group || 'block',
            inline: params.inline || false,
            draggable: params.draggable,
            atom: true,
            addAttributes() {
                return {
                    filler: {
                        default: 'writer'
                    },
                    required: {
                        default: false
                    },
                    tooltip: {
                        default: ''
                    },
                    uuid: {
                        default: ''
                    },
                    ...params.attrs
                }
            },

            parseHTML() {
                return [
                    {
                        tag: params.tag,
                    },
                ]
            },

            renderHTML({ HTMLAttributes }) {
                return [params.tag, mergeAttributes(HTMLAttributes)]
            },

            addNodeView() {
                return VueNodeViewRenderer(component)
            },
        })

    }
}
