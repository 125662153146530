<template lang="pug">
    Extension(v-bind="$props", @delete="deleteNode", :fillable="false" @save="saveImage" @dismiss="resetImage" :popup-class="popup")
        template(#editable)
            v-img(:src="image" v-if="image" :width="popupWidth")
            span.placeholder-image(v-else)
                v-icon mdi-file-image-plus-outline
        template(#preview)
            v-img(:src="image" v-if="image" :width="popupWidth")
            span.placeholder-image(v-else)
                v-icon mdi-file-image-plus-outline

        template(#popup-data-writer)
            .file-selector
                v-file-input(
                    :rules="rules"
                    full-width
                    :value="selectedImage"
                    hide-input
                    accept="image/png, image/jpeg, image/bmp",
                    placeholder="Pick an image"
                    prepend-icon="mdi-camera"
                    @change="processImage"
                )
                h4(v-if="selectedImage") Selected image
                .image(v-if="selectedImage")
                    v-img(:src="selectedImage")

                    v-btn(icon @click="clearImage" title="Clear image")
                        v-icon mdi-file-image-remove-outline
                .placeholder(v-else)
                    v-icon mdi-file-image-plus-outline
                    | Select image
                .resize(v-if="image")
                    div
                        b Resize image
                        br
                    span
                        b Image width:
                        span {{popupWidth}}px
                    v-slider(
                        v-model="popupWidth"
                        class="align-self-stretch"
                        :min="minWidth"
                        :max="maxWidth"
                        step="1"
                        thumb-label
                        append-icon="mdi-magnify-plus-outline"
                        prepend-icon="mdi-magnify-minus-outline"
                        @click:append="zoomIn"
                        @click:prepend="zoomOut"
                        @start="hidePopup"
                        @end="showPopup"
                    )
        template(#popup-data-signer)
        template(#popup-support)
            p This widget allows you to select a date that will be displayed for the client.
            br
            p If you set the widget so that the client fills it in, they will set the date instead.

</template>

<script>
import { nodeViewProps } from '@tiptap/vue-2'
import Extension from "./Extension.vue";

export default {
    editorParams: {
        name: 'vueImage',
        attrs: {
            image: {
                default: null
            },
            width: {
                default: 250
            }
        },
        tag: 'vue-image',
        group: 'inline',
        inline: true,
        draggable: true,
    },
    components: {Extension},
    props: nodeViewProps,

    data(){
        return{
            rules: [
                value => !value || value.size < 1000000 || 'Image size should be less than 1 MB!',
            ],
            selectedImage: this.image,
            popupWidth: this.width || 250,
            minWidth: 100,
            maxWidth: 750,
            originalImage: this.image,
            popup: null,
            timeout: null
        }
    },

    methods:{
        async processImage(image){
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = () => this.setImage(reader.result);
        },

        hidePopup(){
            this.popup = 'scrolling'
        },

        showPopup(){
            this.popup = null
        },

        popupTimeout(){
            clearTimeout(this.timeout);
            this.popup = 'scrolling'
            this.timeout = setTimeout(() => {
                this.popup = null;
            }, 500)
        },

        zoomIn(){
          this.width = Math.min(this.maxWidth, this.width + 10);
          this.popupTimeout()
        },

        zoomOut(){
            this.width = Math.max(this.minWidth, this.width - 10);
            this.popupTimeout()
        },

        saveImage(){
            this.setImage(this.selectedImage);
            this.originalImage = this.selectedImage;
            this.updateAttributes({
                width: this.popupWidth
            })
        },

        setImage(image){
            this.selectedImage = image;
            this.updateAttributes({
                image: image,
            })
        },
        clearImage(){
            this.selectedImage = null;
        },
        resetImage(){
            this.setImage(this.originalImage);
            this.updateAttributes({
                width: this.width,
            })
            this.selectedImage = this.originalImage;
            this.popupWidth = this.width;
        },
    },

    computed:{
        image(){
            return this.node.attrs.image
        },
        width(){
            return this.node.attrs.width
        }
    }
}
</script>



<style lang="scss" scoped>
img{
    max-width: 100%;
}

.controls{
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding: 15px;
}

.extension.editable::v-deep .controls{
    background: rgba(250, 250, 250, 0.75);
}

.placeholder-image{
    border: 3px solid #AAAAAA;
    border-radius: 10px;
    display: inline-block;
    padding: 50px;

    ::v-deep i.v-icon{
        font-size: 75px;
    }
}

.file-selector{
    position: relative;

    .placeholder{
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        height: 150px;
        line-height: 150px;
        text-align: center;

        ::v-deep i.v-icon{
            margin-right: 5px;
        }
    }

    .image{
        border-radius: 5px;
        border: 1px solid #E0E0E0;
        padding: 5px;
        position: relative;
        min-height: 50px;

        ::v-deep .v-btn{
            position: absolute;
            top: 10px;
            right: 10px;
            opacity: 0.75;
            background: #FFFFFF;
            border: 1px solid #E0E0E0;

            .v-icon{
                color: #FF5555;
            }

            &:hover{
                opacity: 1;
                background: #FFFFFF;
            }
        }
    }

    ::v-deep {

        .v-text-field{
            position: absolute;
            width: 100%;
            height: 100%;


            button{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;

                &:before{
                  content: '';
                }

                &:after{
                    display: none;
                }
            }
        }
    }
}
</style>

<style lang="scss">

.v-dialog.scrolling{
    opacity: 0.1;
}
</style>
