<template lang="pug">
    node-view-wrapper.extension(:as="element" :class="{writer: allowSignerFill, signer: allowWriterFill, 'signer-fillable': signerFillable, filled, editable: editor.isEditable, fillable, span: element === 'span', selected: selected && allowSelection, replaced, required: isRequired}" data-drag-handle)
        .controls(v-if="editor.isEditable")
            span.spacing
            v-btn(@click="edit" title="Edit widget" text color="secondary" icon)
                v-icon mdi-pencil
            v-btn.delete(@click="deleteElement" title="Delete widget" text color="accent" icon)
                v-icon mdi-trash-can-outline
            span.spacing
            slot(name="controls")
                //span.drag(data-drag-handle)
                //    v-icon(size="35", title="Drag element") mdi-drag
                //v-icon(@click="setFiller('writer')", v-if="allowWriterFill", title="Set as writer fillable") mdi-file-document-edit-outline
                //v-icon(@click="setFiller('signer')", v-if="allowSignerFill", title="Set as signer fillable") mdi-file-sign
                //v-icon(v-if="settings", @click="showSettings", title="Element settings") mdi-cog
                //slot(name="toolbar")
                //v-icon.delete(@click="deleteElement", title="Delete element") mdi-delete
                //.spacer
        slot(name="render")
            span.editor(v-if="editor.isEditable")
                slot(name="editable")
            span.preview(v-else)
                v-tooltip(color="primary" top :open-on-focus="false" :open-on-click="false" :content-class="tooltipClass") {{ widgetTooltip }}
                    template(#activator="{ on, attrs }")
                        span(v-bind="attrs" v-on="on")
                            slot(name="preview")
        slot(name="popup")
            v-dialog(ref="dialog" v-model="modal" persistent width="350px" :content-class="popupClass" )
                v-card
                    v-card-title
                        slot(name="popup-title") Edit {{name}}
                    v-divider
                    v-card-text
                        slot(name="popup-filler")
                            .filler-select(v-if="fillable")
                                h3.title Who fills in this widget?
                                .select
                                    v-radio-group(v-model="selectedFiller")
                                        v-radio(color="primary" value="writer" label="You")
                                        v-radio(color="secondary" value="signer" label="Your client" )
                        slot(name="popup-fields")
                            h3.fields-title Widget fields
                            .popup-data-writer(v-show="selectedFiller === 'writer'")
                                slot(name="popup-data-writer" :popup="$refs.dialog")
                            .popup-data-signer(v-show="selectedFiller === 'signer'")
                                slot(name="popup-data-signer" :popup="$refs.dialog")
                        .default-fields
                            v-text-field(placeholder="Hover tooltip for signer (optional)" v-model="tooltip" v-if="!replaced")
                            v-checkbox(v-model="required" v-if="(selectedFiller === 'signer' && fillable) || signerOnly" label="Required")
                        v-expansion-panels
                            v-expansion-panel
                                v-expansion-panel-header(disable-icon-rotate expand-icon="mdi-help-circle-outline")
                                    .support-title Details
                                v-expansion-panel-content
                                    .support-text
                                        slot(name="popup-support")
                    v-divider
                    v-card-actions
                        v-spacer
                            v-btn(color="primary" text @click="save") Save
                            v-btn(text @click="dismiss") Dismiss
</template>
<script>
import {nodeViewProps, NodeViewWrapper} from '@tiptap/vue-2'
import { nanoid } from 'nanoid'

export default {
    components: {
        NodeViewWrapper,
    },

    methods:{
        deleteElement(){
            this.$emit('delete');
        },
        setFiller(filler){
            console.log(filler);
            this.node.attrs.filler = filler;
            this.updateAttributes({
                filler: filler
            })
            if(!this.signerOnly && filler === 'writer'){
                this.setRequired(false);
            }
        },
        setRequired(required){
            this.node.attrs.required = required;
            this.updateAttributes({
                required: required
            })
            this.required = required;
        },
        setTooltip(tooltip){
            this.node.attrs.tooltip = tooltip;
            this.updateAttributes({
                tooltip: tooltip
            })
        },
        edit(){
            this.modal = true;
        },
        dismiss(){
            this.modal = false;
            this.setDefaultAttributes();
            this.$emit('dismiss');
        },
        save(){
            this.setFiller(this.selectedFiller);
            this.setRequired(this.required)
            this.setTooltip(this.tooltip)
            this.$nextTick(() => {
                this.$emit('save');
                this.dismiss();
            })
        },
        setDefaultAttributes(){
            this.selectedFiller = this.filler;
            this.required = this.isRequired;
            this.tooltip = this.widgetTooltip;
        }
    },

    data(){
        return {
            modal: false,
            selectedFiller: null,
            required: false,
            tooltip: '',
        }
    },

    computed: {
        filler(){
            return this.node.attrs.filler;
        },
        isRequired(){
            return this.node.attrs.required;
        },
        widgetTooltip(){
            return this.node.attrs.tooltip;
        },
        tooltipClass(){
            if(!this.widgetTooltip){
                return 'hidden';
            }
            return '';
        },
        signerFillable(){
            return this.selectedFiller === 'signer' || this.signerOnly;
        },
        allowWriterFill(){
            return this.editor.name !== 'email' && this.fillable && !this.editable
        },
        allowSignerFill(){
            return this.editor.name !== 'email' && this.fillable && this.editable;
        },
        editable(){
            let editable = false;
            if(this.editor.isEditable){
                if(this.filler === 'writer'){
                    editable = true;
                }
            }
            return editable;
        },
    },

    props: {
        fillable: {
            type: Boolean,
            default: true
        },
        element: {
            type: String,
            default: 'span'
        },
        name: {
            type: String,
            default: 'widget'
        },
        settings: {
            type: Boolean,
            default: false
        },
        allowSelection: {
            type: Boolean,
            default: false
        },
        signerOnly:{
            type: Boolean,
            default: false
        },
        popupClass: {
            type: String,
            default: null
        },
        replaced: {
            type: Boolean,
            default: false
        },
        defaultRequired: {
          type: Boolean,
          default: false
        },
        filled:{
            type: Boolean,
            default: false
        },
        ...nodeViewProps,
        decorations: {
            required: false
        }
    },
    mounted() {

        setTimeout(() => {
            this.setDefaultAttributes();
            if(!this.node.attrs.uuid){
                this.updateAttributes({
                    uuid: nanoid(50)
                })
            }

            if(this.signerOnly){
                this.setFiller('signer');
            }

            if(this.defaultRequired){
                this.setRequired(true);
            }

            if(!this.editor.isEditable){
                this.$store.commit('setSignedField', {
                    uuid: this.node.attrs.uuid,
                    value: null
                })
                console.log(this.isRequired, this.node.attrs.uuid)
                console.log(this.signerFillable, this.node.attrs.uuid)

                if(this.isRequired && this.signerFillable){
                    this.$store.commit('setRequiredField', {
                        uuid: this.node.attrs.uuid,
                        value: this.filled
                    })
                }
            }
            if(this.replaced){
                this.setRequired(false);
            }
        });
    },

    watch: {
        filled(){
            this.$store.commit('setRequiredField', {
                uuid: this.node.attrs.uuid,
                value: this.filled
            })
        },
        selectedFiller(){
            console.log('filler', this.filler);
            console.log('filler', this.defaultRequired);
            if(this.defaultRequired && this.selectedFiller === 'signer'){
                this.setRequired(true);
                this.required = true;
            }
        }
    }



}
</script>



<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.extension{
    position: relative;

    &:before{
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        position: absolute;
        top: -5px;
        left: -5px;
        content: ' ';
        opacity: 0;
        border-radius: 10px;
        background: #000000;
        z-index: 0;
    }

    &.replaced:not(.editable){

        &:before{
            display: none;
        }
    }

    &.editable{
        cursor: move;
        user-select: none;
        outline: 2px solid rgba(115, 115, 115, 0.15);
        border-radius: 5px;
        padding: 0.5rem;
        margin-left: 3px;
        margin-right: 3px;

        &:before{
            z-index: 10;
        }

        &:hover{
            //box-shadow: 0px 0px 15px 3px rgba(0, 0, 100, 0.1);
            background-color: rgba(115, 115, 115, 0.15);
        }

        &.writer{
            outline: 2px solid rgba(14, 79, 185, 0.5);


            &:before{
                background: $color-primary--dark;
            }

            &:hover{
                background-color: rgba(14, 79, 185, 0.15);
            }
        }

        &.signer{
            outline: 2px solid rgba(25, 150, 111, 0.5);

            &:before{
                background: $color-secondary--dark;
            }

            &:hover{
                background-color: rgba(25, 150, 111, 0.15);
            }
        }
    }


    &.signer-fillable{
        outline: 2px solid rgba(14, 79, 185, 0.5);
        border-radius: 5px;
        padding: 0.5rem;
        margin-left: 3px;
        margin-right: 3px;

        &.required{
            outline: 2px solid rgba(#ae2d18, .35);
        }

        &.filled{
            outline: 2px solid rgba(25, 150, 111, 0.5);
            background-color: rgba(25, 150, 111, 0.15);

            &:hover{
                background-color: rgba(25, 150, 111, 0.15);
            }
        }

        &:before{
            background: $color-primary--dark;
        }

        &:hover{
            background-color: rgba(14, 79, 185, 0.15);
        }
    }

    &.selected{
        box-shadow: 0px 0px 3px 3px rgba(0, 0, 100, 0.1);
    }

    &.span{
        display: inline-block;
    }

    .controls{
        display: none;
    }

    .editor{
        position: relative;
        z-index: 100;

        display: inline-block;
    }

    &.editable{

        .controls{
            line-height: 10px;
            position: absolute;
            width: 100%;
            height: 100%;
            //top: -35px;
            //left: 50%;
            //margin-left: -100px;
            //margin-bottom: 25px;
            top: 0px;
            left: 0px;
            background: rgba(25, 150, 111, 0);
            backdrop-filter: blur(2px);
            white-space: nowrap;
            //background-color: #FFFFFF;
            opacity: 0;
            transition: opacity 0.35s;
            display: flex;
            justify-content: space-between;
            align-items: center;
            visibility: hidden;
            z-index: 1000;
            //box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.35);
            //border-radius: 50px;
            //overflow: hidden;

            .v-btn .v-icon{
                //width: 100px;
                text-align: center;
                color: $color-secondary--dark;
            }

            .v-btn.delete .v-icon{
                color: $color-accent--dark;
            }

            .v-btn:hover:before{
                opacity: 0.5;
            }

            .drag{
                height: 35px;
                position: absolute;
                top: 0px;
                left: 0px;
                border-right: 1px solid #FFFFFF;
                cursor: move;

                .v-icon{
                    margin-left: 0px;
                }
            }

            .spacer{
                width: 100%;
                position: absolute;
                bottom: -100%;

                height: 100%;
                left: 0px;
            }

            .v-icon{
                color: #FFFFFF;
                margin-left: 0px;

                &.delete{
                    color: #FF7777;
                }
            }
        }

        &:hover{

            .controls{
                visibility: visible;
                opacity: 1;
            }
        }

    }

    span.preview{
        display: inline-block;

        & > span{
            display: inline-block;
        }
    }
}

::v-deep .v-card__text{
    margin-top: 15px;
}

.v-expansion-panel{

    &:before{
        display: none;
    }


    [type="button"]{
        min-height: 35px;
        padding: 0px;

        margin-top: 5px;
    }

    ::v-deep .v-expansion-panel-content__wrap{
        padding: 0px;
    }

    .support-title{
        text-align: left;
        font-weight: bold;
    }

    .support-text{
        margin-top: 10px;
    }
}

h3.fields-title{
    margin-bottom: 15px;
}

.default-fields{
    margin-top: 15px;
}

.v-input--radio-group{
    margin-top: 0px;
}

.v-text-field{
    padding-top: 0px;
}

::v-deep{
    .v-messages{
        display: none
    }
}



.v-input--checkbox{
    margin-top: 0px;
    margin-bottom: 15px;
}
</style>

<style>

.v-tooltip__content.hidden{
    display: none;
}

</style>
