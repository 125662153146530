<template lang="pug">
    div
        canvas(ref="signature", width="500", height="300")
</template>

<script>
import SignaturePad from 'signature_pad'

export default {
    methods:{
        signatureInit(){
            if(this.value){
                this.signaturePad.fromDataURL(this.value);
            }
        },
        input(){
            this.$emit('input', this.signaturePad.toDataURL("image/svg+xml"));
        }
    },
    props: ['value'],
    data(){
        return{
            signaturePad: null
        }
    },

    mounted(){
        const canvas = this.$refs.signature;
        this.signaturePad = new SignaturePad(canvas);
        const ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.width * ratio;
        canvas.height = canvas.height * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        this.signaturePad.clear();


        this.signaturePad.addEventListener("endStroke", () => {
            this.input();
        });

        this.signatureInit();
    },
    watch: {
        value(){
            this.signatureInit();
        }
    }
}
</script>



<style lang="scss" scoped>
canvas{
    width: 500px;
    height: 300px;
}
</style>
