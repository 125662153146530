<template lang="pug">
    Extension(v-bind="$props" @delete="deleteNode" :fillable="false" :allow-selection="true" :replaced="true")
        template(#render) {{email}}
        template(#popup-fields)
            b No editable fields
        template(#popup-support)
            p This widget will be replaced with the email address of the signer when they open the contract.
</template>

<script>
import { nodeViewProps } from '@tiptap/vue-2'
import Extension from "./Extension.vue";

export default {
    editorParams: {
        name: 'vueEmail',
        attrs: {},
        tag: 'vue-email',
        group: 'inline',
        inline: true,
        draggable: true,
    },
    components: {Extension},
    props: nodeViewProps,

    data(){
        return{
        }
    },

    computed:{
        email(){
                return this.$store.state.selectedPerson.email || '[Person email]'
        }
    }
}
</script>



<style lang="scss" scoped>
</style>
