<template lang="pug">
    .editor-preview
        v-card.document-holder
            v-card-text
                editor-content(:editor="editor")
        v-card.schedule-a(v-if="!hideScheduleA")
            v-card-title Schedule A
            v-card-text
                .songs(v-if="songs")
                    v-data-table(
                        :headers="scheduleA"
                        :items = "songs"
                        disable-pagination
                        hide-default-footer
                        :no-data-text="empty"
                        :hide-default-header="!songs.length"
                    )
                        template(#item.name="{ item }")
                            b {{item.name}}
                            div
                                small {{item.artist}}
                        template(#item.composers="{ item }")
                            .composers.rights(:class="{error: totalRights(item.royalties, 'songwriting') !== 100}")
                                .composer(v-for="composer in getRightsholders(item.royalties, 'songwriting')")
                                    span.name {{composer.name}}
                                    span.bmi(v-if="composer.industry_id") &nbsp;({{composer.pro_type}} {{composer.industry_id}})
                                    span.percentage &nbsp;- {{composer.pivot.songwriting}}%
                                .total
                                    b Total: {{totalRights(item.royalties, 'songwriting')}}%
                        template(#item.publishers="{ item }")
                            .publishers.rights(:class="{error: totalRights(item.royalties, 'publishing') !== 100}")
                                .publisher(v-for="composer in getRightsholders(item.royalties, 'publishing')")
                                    span.name {{composer.name}}
                                    span.bmi(v-if="composer.industry_id") &nbsp;({{composer.pro_type}} {{composer.industry_id}})
                                    span.percentage &nbsp;- {{composer.pivot.publishing}}%



                                .total
                                    b Total: {{totalRights(item.royalties, 'publishing')}}%
                        template(#item.masters="{ item }")
                            .masters.rights(:class="{error: totalRights(item.royalties, 'mastering') !== 100}")
                                .master(v-for="composer in getRightsholders(item.royalties, 'mastering')")
                                    span.name {{composer.name}}
                                    span.bmi(v-if="composer.industry_id") &nbsp;({{composer.pro_type}} {{composer.industry_id}})
                                    span.percentage &nbsp;- {{composer.pivot.mastering}}%
                                .total
                                    b Total: {{totalRights(item.royalties, 'mastering')}}%
</template>
<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Extensions from './editor-extensions/Extensions.js';
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from "@tiptap/extension-table-cell";
import TextAlign from "@tiptap/extension-text-align";
import EditorImage from "@tiptap/extension-image";
import TextStyle from '@tiptap/extension-text-style';
import Color from "@tiptap/extension-color";

export default {
    components: {
        EditorContent,
    },

    props: {
        value: {
            type: String,
            default: '',
        },
        hideScheduleA: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            editor: null,
            scheduleA: [
                { text: '', value: 'name', sortable: false},
                { text: 'Composers', value: 'composers', sortable: false},
                { text: 'Publishers', value: 'publishers', sortable: false},
                { text: 'Masters', value: 'masters', sortable: false},
            ],
        }
    },
    methods: {
        getRightsholders(people, rights){
            return people.filter(person => person.pivot[rights]);
        },
        totalRights(people, rights){
            return (this.getRightsholders(people, rights).map(person => person.pivot[rights])).reduce((total, percentage) => total + percentage, 0);
        }
    },

    computed: {
        songs(){
            return this.$store.getters['songsForSelectedPerson'];
        },
        empty(){
            const selectedSongs = this.$store.state.selectedSongs;
            if(selectedSongs.length === 0){
                return 'No songs have been selected yet'
            }else{
                return 'No rightsholders have been selected'
            }
        }
    },

    mounted() {

        this.editor = new Editor({
            content: this.value,
            editable: false,
            extensions: [
                StarterKit,
                Table,
                TableRow,
                TableHeader,
                TableCell,
                EditorImage.configure({
                    inline: true,
                    allowBase64: true
                }),
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                TextStyle,
                Color,
                ...Extensions
            ],
        })

    },

    beforeDestroy() {
        this.editor.destroy()
    },

    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
            if (isSame) {
                return
            }
            this.editor.commands.setContent(value, false)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.editor-preview{
    padding: 16px;
}

.percentage{
    font-weight: bold;
}

.songs{

    li{
        list-style-type: none;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .song-name{

        span{
            font-weight: bold;
        }
    }

    .song-royalties{
        margin-left: 10px;
        margin-top: 5px;
    }

    &::v-deep{

        .v-data-table__empty-wrapper{
            position: relative;
            top: 15px;
        }
    }
}

.rights .total{
    background: $color-primary--bright;
    color: $color-primary--normal;

    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
}

.rights.error .total{
}

.editor-preview::v-deep{
    text-align: left;

    table{
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;

        tr{

            td{
                width: 50%;
                border: none;

                padding: 15px;

                vertical-align: middle;

                & + td{
                    vertical-align: bottom;
                }
            }
        }
    }
}

.document-holder{
    margin-bottom: 1rem;
}

.v-card::v-deep .v-card__title{
    justify-content: center;
}
</style>
