<template lang="pug">
    Extension(v-bind="$props", :fillable="false" :replaced="true")
        template(#render) {{ipAddress}}
        template(#popup-fields)
            b No editable fields
        template(#popup-support)
            p This widget will be replaced with the IP address of the signer when they open the contract.
            br
            p IP address is saved whether you place this widget or not, this is strictly for visual purposes.
</template>

<script>
import { nodeViewProps } from '@tiptap/vue-2'
import Extension from "./Extension.vue";

export default {
    editorParams: {
        name: 'vueIp',
        attrs: {
            ip: {
                default: 'xxx.xxx.xxx.xxx'
            }
        },
        tag: 'vue-ip',
        group: 'inline',
        inline: true,
        draggable: true,
    },
    components: {Extension},
    props: nodeViewProps,


    computed:{
        ipAddress(){
            return this.node.attrs.ip
        }
    },
}
</script>



<style lang="scss" scoped>
</style>
