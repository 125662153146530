<template lang="pug">
    Extension(v-bind="$props" @delete="deleteNode" :fillable="false" @save="save" @dismiss="dismiss" replaced)
        template(#render)
            a(href="#") {{text}}
        template(#popup-data-writer)
            v-text-field(v-model="inputText" placeholder="Address label")
        template(#popup-support)
            p This widget will display a link to the sign page for this contract.
            br
            p The text you put in the
                b &nbsp;Address label&nbsp;
                | input will show up as the link text.
            br

            p If empty it will show the URL instead.
</template>

<script>
import { nodeViewProps } from '@tiptap/vue-2'
import Extension from "./Extension.vue";

export default {
    editorParams: {
        name: 'vueSignAddress',
        tag: 'vue-sign-address',
        group: 'inline',
        inline: true,
        draggable: true,


        attrs: {
            text: {
                default: null
            }
        }
    },
    components: {Extension},
    props: nodeViewProps,
    data(){
        return {
            inputText: this.node.attrs.text
        }
    },
    computed:{
        text(){
            let text = '[Sign URL shows here]';
            if(this.node.attrs.text){
                text = this.node.attrs.text;
            }
            return text;
        }
    },
    methods: {
        save(){
            this.setText(this.inputText);
        },
        dismiss(){
            this.setText(this.text);
            this.inputText = this.text;
        },
        setText(text){
            this.updateAttributes({
                text: text
            })
            this.node.attrs.text = text
        },
    }
}
</script>



<style lang="scss" scoped>

.extension.editable{
    min-width: 75px;
    text-align: center;
}
</style>
