import ExtensionHelper from './Extension.js'
import Date from './Date.vue'
import Name from './Name.vue'
import UserName from './UserName.vue'
import Email from './Email.vue'
import SignatureWriter from "./SignatureWriter";
import Signature from "./Signature";
import IpAddress from "./IpAddress";
import TextField from "./TextField";
import Image from "./Image";
import SignAddress from "./SignAddress";


const extensions = [Date, Name, UserName, SignatureWriter, Signature, IpAddress, TextField, Image, Email, SignAddress];
let plugins = [];
extensions.forEach(extension => {
    const plugin = ExtensionHelper.render(extension)
    if(plugin){
        plugins.push(plugin)
    }
});
export default plugins;
