<template lang="pug">
    Extension(v-bind="$props", @delete="deleteNode", :settings="true", @settings="showSettings", :default-required="true", :fillable="true" @save="save" @dismiss="dismiss" :filled="signed")
        template(#editable)
            span.holder(v-if="filler === 'writer'" ref="signatureInput")
                span.signature(v-if="name") {{name}}
                span.signature.placeholder(v-else) Add signature here
            span.holder(v-else ref="signatureInput")
                span.signature(v-if="name") {{name}}
                span.signature.placeholder(v-else) User signs here
        template(#preview)
            span.holder(v-if="signedName")
                span.signature(v-if="name") {{name}}
                span.signature.placeholder(v-else) User signs here
            span.preview.holder(v-else ref="signatureSpan")
                span.signature(v-if="name && signed") {{name}}
                span.signature(v-else-if="!name && signed") User signature
                span.signature.placeholder(v-else) Check box for signature
                v-checkbox(v-model="signed" @click="updateSelect" :label="checkboxText" hide-details)
        template(#popup-data-writer)
            v-text-field(v-model="signedNameInput" placeholder="Sign contract as" disabled)
        template(#popup-data-signer)
            v-text-field.checkbox(v-model="checkboxPlaceholder" placeholder="Text shown for the signature checkbox(optional)")
        template(#popup-support)
            p This widget will display a cursive signature with the name you set.
            br
            p If you set the widget so that the client fills it in, they will instead click a checkbox which will automatically fill in the signature with their name.

</template>

<script>
import { nodeViewProps } from '@tiptap/vue-2'
import Extension from "./Extension.vue";

export default {
    editorParams: {
        name: 'vueSignature',
        attrs: {
            signedName: {
                default: null
            },
            signedImage: {
                default: null
            },
            checkboxText: {
                default: 'I agree with the contract terms'
            }
        },
        tag: 'vue-signature',
        group: 'inline',
        inline: true,
        draggable: true,
    },
    components: {Extension},
    props: nodeViewProps,

    data(){
        return{
            signed: false,
            canvas: document.createElement('canvas'),
            modal: false,
            filled: false,
            signedNameInput: null,
            checkboxPlaceholder: null,
        }
    },

    methods: {
        save(){
            if(this.node.attrs.filler === 'writer'){
                this.updateTerms(null);
                this.updateName(this.signedNameInput);
            }
            if(this.node.attrs.filler === 'signer'){
                this.updateTerms(this.checkboxPlaceholder);
                this.setSignedName(null, null);
            }
        },
        dismiss(){
            this.signedNameInput = this.signedName;
            this.checkboxPlaceholder = this.checkboxText;
        },
        updateName(value){
            const signature = this.$refs.signatureInput.querySelector('.signature');
            const canvasImage = this.canvasImage(value, signature);
            console.log(canvasImage);

            this.updateAttributes({
                signedName: value,
                signedImage: canvasImage
            })
        },
        setSignedName(name, image){
            this.updateAttributes({
                signedName: name,
                signedImage: image
            })
            this.node.attrs.signedName = name
        },
        updateSelect(){
            if(this.signed && this.name){
                this.$nextTick(() => {
                    const canvasImage = this.canvasImage(this.name, this.$refs.signatureSpan.querySelector('.signature'));
                    this.setStoreField(canvasImage);
                    this.filled =  true;
                });
            }else{
                this.setStoreField(null);
                this.filled = false;
            }
        },
        setStoreField(value){
            this.$store.commit('setSignedField', {
                uuid: this.node.attrs.uuid,
                value: value
            })
        },
        canvasEmpty(){
            const context = this.canvas.getContext("2d");
            context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        },
        canvasImage(value, element){
            this.canvasEmpty();
            const input = element.closest('.extension');
            console.log(input);
            const canvas = this.canvas;
            canvas.width = input.offsetWidth + 15;
            canvas.height = input.offsetHeight
            const context = canvas.getContext("2d");
            context.font = "lighter 50px Great Vibes";
            context.textAlign = 'center';
            context.fillText(value, canvas.width / 2, canvas.height / 2 + 15);
            return canvas.toDataURL();
        },
        updateTerms(value){
            this.updateAttributes({
                checkboxText: value,
            })
        },
        showSettings(){
            this.modal = true;
        },
        updateSignerFromStore(){
            if(this.filler === 'writer'){
                this.signedNameInput = this.storeSigner
                setTimeout(() => {
                    this.updateName(this.signedNameInput);
                })
            }
        }
    },

    mounted(){
        this.signedNameInput = this.signedName;
        console.log(this.node.attrs.signedImage)
        if(!this.signedName){
            this.updateSignerFromStore();
        }
    },

    computed:{
        filler(){
            return this.node.attrs.filler
        },
        storeSigner(){
            return this.$store.state.signingUser;
        },
        signedName(){
            return this.node.attrs.signedName
        },
        checkboxText(){
            return this.node.attrs.checkboxText
        },
        name(){
            if(this.signedName){
                return this.signedName;
            }
            if(this.signed) {
                return this.$store.state.selectedPerson.name
            }
            return null;
        }
    },
    watch: {
        storeSigner(){
            if(this.storeSigner){
                this.updateSignerFromStore()
            }
        }
    }
}
</script>



<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

canvas{
    visibility: hidden;
    position: absolute;
}


.holder{
    min-height: 75px;
    position: relative;
    top: 20px;

    margin-bottom: -20px;
    display: inline-block;

    &.preview{
        margin-bottom: 15px;
    }
}

.extension{
    padding-bottom: 3em !important;
}

.signature{
    min-width: 300px;
    display: inline-block;
    font-family: 'Great Vibes', cursive;
    font-size: 50px;
    border-bottom: 1px solid #000000;
    text-align: center;
    line-height: 1.25;
    margin-top: -0.5em;



    &.v-text-field::v-deep{
        border: none;

        input{
            height: 70px;
            max-height: 70px;

            padding: 5px;
            text-align: center;
        }
    }

    &.placeholder{
        color: #AAAAAA;
    }
}
</style>
